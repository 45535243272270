.requisites-info {
  padding: 7rem 20rem;
}

.requisites-table {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  margin: 2rem 0;
}

.requisites-table-name,
.requisites-table-info {
  padding: 1rem 0;
  font-size: 1.2rem;
  background-color: #fff;
}

.requisites-table-name {
  color: rgba(17, 17, 17, 0.56);
}

.requisites-info-border {
  border-bottom: 1px solid rgba(17, 17, 17, 0.24);
}

@media screen and (max-width: 768px) {
  .requisites-info {
    padding: 10rem 2rem;
  }
}
