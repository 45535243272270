@import url("./assets/fonts/Scripticus/stylesheet.css");
@import url("./assets/fonts/Lora/stylesheet.css");
@import url("./assets/fonts/Montserrat/stylesheet.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Lora";
  font-size: clamp(8px, 0.9375vw, 22px);
}
