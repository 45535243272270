.h1 {
  position: relative;
  display: block;
}

.h1-white,
.h1-orange {
  font-family: "Scripticus";
  font-size: 16rem;
  text-transform: uppercase;
}

.h1-white {
  color: #ffffff;
}

.h1-orange {
  position: absolute;
  top: -0.5rem;
  left: -0.5rem;
  color: #fd5a46;
}

.temp-title {
  color: #fff;
  font-family: "Scripticus";
  font-size: 8rem;
  text-transform: uppercase;
}

.h2 {
  display: inline-block;
  width: auto;
  padding: 1rem;
  font-family: "Montserrat";
  text-align: left;
  font-size: 3.5rem;
  color: #fff;
  text-transform: uppercase;
  background-color: #fd5a46;
}
