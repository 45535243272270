.logo {
  position: relative;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.logo-white,
.logo-orange {
  font-family: "Scripticus";
}

.logo-white {
  color: #ffffff;
}

.logo-orange {
  position: absolute;
  top: -0.055rem;
  left: -0.055rem;
  color: #fd5a46;
}
