.header {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.5rem;
  padding: 0 13rem;
}

@media screen and (max-width: 768px) {
  .header {
    display: none;
  }
}
