.page-404 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.page-404-wrap {
  position: relative;
  display: block;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.page-404-white,
.page-404-orange {
  font-family: "Scripticus";
  font-size: 8rem;
  text-transform: uppercase;
}

.page-404-white {
  color: #ffffff;
}

.page-404-orange {
  position: absolute;
  top: -0.055rem;
  left: -0.055rem;
  color: #fd5a46;
}